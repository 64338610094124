<template>
    <div>
        <!-- Form -->
        <b-card no-body>
            <b-card-body>
                <b-row>
                    <b-col cols="12">
                        <validation-observer ref="dataForm" #default="{ invalid }">
                            <b-form ref="form" @submit.stop.prevent="">
                                <b-row>
                                    <b-col cols="12" md="6">
                                        <b-row v-if="'' + client.type === '1'">
                                            <b-col cols="12" class="pb-1">
                                                <b-form-checkbox
                                                    checked="false"
                                                    class="custom-control-success"
                                                    name="isStaff"
                                                    v-model="user.isStaff"
                                                    :value="!user.isStaff"
                                                >
                                                    <span>Grower's Account?</span>                                 
                                                </b-form-checkbox>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col cols="12" md="3">
                                                <b-avatar
                                                    :src="require('@/assets/images/avatars/1.png')"
                                                    size="130px"
                                                    rounded
                                                />
                                            </b-col>
                                            <b-col cols="12" md="9">
                                                <b-row>
                                                    <b-col cols="12" md="4">
                                                        <b-form-group labe-for="firstName" label="First Name">
                                                            <validation-provider #default="{ errors }" name="First Name" rules="">
                                                                <b-form-input
                                                                    id="firstName"
                                                                    name="firstName"
                                                                    v-model="user.firstName"
                                                                    :state="errors.length > 0 ? false : null"
                                                                    size="sm"
                                                                />
                                                                <small class="text-danger">{{ errors[0] }}</small>
                                                                <small class="text-danger" v-if="serverErrors && serverErrors.firstName">{{ serverErrors.firstName[0] }}</small>
                                                            </validation-provider>
                                                        </b-form-group>
                                                    </b-col>

                                                    <b-col cols="12" md="4">
                                                        <b-form-group labe-for="middleName" label="Middle Name">
                                                            <validation-provider #default="{ errors }" name="Middle Name" rules="">
                                                                <b-form-input
                                                                    id="middleName"
                                                                    name="middleName"
                                                                    v-model="user.middleName"
                                                                    :state="errors.length > 0 ? false : null"
                                                                    size="sm"
                                                                />
                                                                <small class="text-danger">{{ errors[0] }}</small>
                                                                <small class="text-danger" v-if="serverErrors && serverErrors.middleName">{{ serverErrors.middleName[0] }}</small>
                                                            </validation-provider>
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col cols="12" md="4">
                                                        <b-form-group labe-for="lastName" label="Last Name">
                                                            <validation-provider #default="{ errors }" name="Last Name" rules="">
                                                                <b-form-input
                                                                    id="lastName"
                                                                    name="lastName"
                                                                    v-model="user.lastName"
                                                                    :state="errors.length > 0 ? false : null"
                                                                    size="sm"
                                                                />
                                                                <small class="text-danger">{{ errors[0] }}</small>
                                                                <small class="text-danger" v-if="serverErrors && serverErrors.lastName">{{ serverErrors.lastName[0] }}</small>
                                                            </validation-provider>
                                                        </b-form-group>
                                                    </b-col>
                                                </b-row>
                                                <b-row>
                                                    <b-col cols="12" md="4">
                                                        <b-form-group labe-for="gender" label="Gender">
                                                            <validation-provider #default="{ errors }" name="Gender" rules="">
                                                                <b-form-select
                                                                    size="sm"
                                                                    v-model="user.gender"
                                                                    id="gender"
                                                                    name="gender"
                                                                    :state="errors.length > 0 ? false : null"
                                                                >
                                                                    <b-form-select-option :value="null">Select...</b-form-select-option>
                                                                    <b-form-select-option value="female">Female</b-form-select-option>
                                                                    <b-form-select-option value="male">Male</b-form-select-option>
                                                                </b-form-select>
                                                                <small class="text-danger">{{ errors[0] }}</small>
                                                                <small class="text-danger" v-if="serverErrors && serverErrors.gender">{{ serverErrors.gender[0] }}</small>
                                                            </validation-provider>
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col cols="12" md="4">
                                                        <b-form-group
                                                            labe-for="staffID"
                                                            label="Staff ID"
                                                        >
                                                            <validation-provider
                                                                #default="{ errors }"
                                                                name="Staff ID'"
                                                                rules=""
                                                            >
                                                                <b-form-input
                                                                    id="staffID"
                                                                    name="staffID"
                                                                    v-model="user.staffID"
                                                                    :state="errors.length > 0 ? false : null"
                                                                    size="sm"
                                                                />
                                                                <small class="text-danger">{{ errors[0] }}</small>
                                                                <small class="text-danger" v-if="serverErrors && serverErrors.staffID">{{ serverErrors.staffID[0] }}</small>
                                                            </validation-provider>
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col cols="12" md="4">
                                                        <b-form-group labe-for="status" label="Status">
                                                            <validation-provider #default="{ errors }" name="Status" rules="">
                                                                <b-form-select
                                                                    size="sm"
                                                                    v-model="user.status"
                                                                    id="status"
                                                                    name="status"
                                                                    :state="errors.length > 0 ? false : null"
                                                                >
                                                                    <b-form-select-option value="1">Active</b-form-select-option>
                                                                    <b-form-select-option value="0">Locked</b-form-select-option>
                                                                    <b-form-select-option value="-1">Blocked</b-form-select-option>
                                                                </b-form-select>
                                                                <small class="text-danger">{{ errors[0] }}</small>
                                                                <small class="text-danger" v-if="serverErrors && serverErrors.gender">{{ serverErrors.gender[0] }}</small>
                                                            </validation-provider>
                                                        </b-form-group>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                    <b-col cols="12" md="6">
                                        <h6>Given Permissions</h6>
                                        <div
                                            style="border: 1px solid #eee;height:14vh;overflow-y:scroll;overflow-x: hidden;padding: 8px 8px;"
                                        >
                                            <b-row>
                                                <b-col
                                                    cols="6"
                                                    md="3"
                                                    v-for="(ability, index) in abilities"
                                                    :key="index"
                                                >
                                                    <b-form-checkbox
                                                        class="custom-control-success"
                                                        :value="ability.id"
                                                        :label="ability.title"
                                                        v-model="user.permissions"
                                                        v-if="!role"
                                                    >
                                                        <small>{{ ability.title }}</small>
                                                    </b-form-checkbox>

                                                    <b-form-checkbox
                                                        class="custom-control-success"
                                                        :value="ability.id"
                                                        :label="ability.title"
                                                        v-model="user.permissions"
                                                        v-if="role && !role.permissions.includes(ability.id)"
                                                    >
                                                        <small>{{ ability.title }}</small>
                                                    </b-form-checkbox>

                                                    <b-form-checkbox
                                                        class="custom-control-success"
                                                        :value="ability.id"
                                                        :label="ability.title"
                                                        v-model="role.permissions"
                                                        v-if="role && role.permissions.includes(ability.id)"
                                                        disabled
                                                    >
                                                        <small>{{ ability.title }}</small>
                                                    </b-form-checkbox>
                                                </b-col>
                                            </b-row>
                                        </div>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12" md="2">
                                        <b-form-group labe-for="role_id" label="Assigned Group">
                                            <validation-provider #default="{ errors }" name="Assigned Group" rules="">
                                                <b-form-select
                                                    size="sm"
                                                    v-model="user.role_id"
                                                    id="role_id"
                                                    name="role_id"
                                                    :state="errors.length > 0 ? false : null"
                                                    @change="changeRole()"
                                                >
                                                    <b-form-select-option :value="null">Select...</b-form-select-option>
                                                    <b-form-select-option
                                                        v-for="role in roles"
                                                        :key="role.id"
                                                        :value="role.id"
                                                    >
                                                        {{ role.title }}
                                                    </b-form-select-option>
                                                </b-form-select>
                                                <small class="text-danger">{{ errors[0] }}</small>
                                                <small class="text-danger" v-if="serverErrors && serverErrors.role_id">{{ serverErrors.role_id[0] }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>

                                    <b-col cols="12" md="2">
                                        <b-form-group labe-for="mobile" label="Mobile">
                                            <validation-provider #default="{ errors }" name="Mobile" rules="">
                                                <b-form-input
                                                    size="sm"
                                                    v-model="user.mobile"
                                                    id="mobile"
                                                    name="mobile"
                                                    :state="errors.length > 0 ? false : null"
                                                    placeholder="eg. 07XX XXX XXX"
                                                />
                                                <small class="text-danger">{{ errors[0] }}</small>
                                                <small class="text-danger" v-if="serverErrors && serverErrors.mobile">{{ serverErrors.mobile[0] }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>

                                    <b-col
                                        cols="12"
                                        md="2"
                                        class="mb-md-0 mb-2"
                                        v-if="'' + client.type === '0' && role && role.fieldOps"
                                        v-for="(toSelect, index) in selections"
                                        :key="toSelect.level"
                                    >
                                        <label>{{ toSelect.label }}</label>
                                        <b-form-select
                                            size="sm"
                                            v-model="toSelect.model"
                                            @change="changeFilters(toSelect.model, index)"
                                        >
                                            <b-form-select-option :value="null">Select...</b-form-select-option>
                                            <b-form-select-option
                                                v-for="location in toSelect.locations"
                                                :key="location.id"
                                                :value="location"
                                            >
                                                {{ location.name.toUpperCase() }}
                                            </b-form-select-option>
                                        </b-form-select>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.location_id">{{ serverErrors.location_id[0] }}</small>
                                    </b-col>
                                    <b-col
                                        cols="12"
                                        md="2"
                                        v-if="'' + client.type === '0' && role && role.fieldOps"
                                    >
                                        <label for="sector_id">Sector</label>
                                        <b-form-select
                                            id="sector_id"
                                            name="sector_id"
                                            size="sm"
                                            v-model="user.sector_id"
                                        >
                                            <b-form-select-option :value="null">Select...</b-form-select-option>
                                            <b-form-select-option
                                                v-for="sector in sectors"
                                                :key="sector.id"
                                                :value="sector.id"
                                            >
                                                {{ sector.code.toUpperCase() }}
                                            </b-form-select-option>
                                        </b-form-select>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.sector_id">{{ serverErrors.sector_id[0] }}</small>
                                    </b-col>

                                    <b-col
                                        cols="12"
                                        md="8"
                                        v-if="'' + client.type !== '0' || !role || !role.fieldOps"
                                    >
                                        <b-row>

                                            <b-col cols="3">
                                                <b-form-group labe-for="pers" label="Personal Code">
                                                    <validation-provider #default="{ errors }" name="Personal Code" rules="">
                                                        <b-form-input
                                                            id="pers"
                                                            name="pers"
                                                            v-model="user.pers"
                                                            :state="errors.length > 0 ? false : null"
                                                            size="sm"
                                                        />
                                                        <small class="text-danger">{{ errors[0] }}</small>
                                                        <small class="text-danger" v-if="serverErrors && serverErrors.pers">{{ serverErrors.pers[0] }}</small>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>

                                            <b-col cols="12" md="3">
                                                <b-form-group labe-for="email" label="Email/Username">
                                                    <validation-provider #default="{ errors }" name="Email/Username" rules="">
                                                        <b-form-input
                                                            id="email"
                                                            name="email"
                                                            v-model="user.email"
                                                            :state="errors.length > 0 ? false : null"
                                                            size="sm"
                                                        />
                                                        <small class="text-danger">{{ errors[0] }}</small>
                                                        <small class="text-danger" v-if="serverErrors && serverErrors.email">{{ serverErrors.email[0] }}</small>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>
                                            <b-col cols="12" md="3">
                                                <b-form-group labe-for="password" label="Password">
                                                    <validation-provider #default="{ errors }" name="Password" rules="">
                                                        <b-form-input
                                                            id="password"
                                                            name="password"
                                                            v-model="user.password"
                                                            :state="errors.length > 0 ? false : null"
                                                            size="sm"
                                                            type="password"
                                                        />
                                                        <small class="text-danger">{{ errors[0] }}</small>
                                                        <small class="text-danger" v-if="serverErrors && serverErrors.password">{{ serverErrors.password[0] }}</small>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>
                                            <b-col cols="12" md="3">
                                                <b-form-group labe-for="password_confirmation" label="Confirm Password">
                                                    <validation-provider #default="{ errors }" name="Confirm Password" rules="">
                                                        <b-form-input
                                                            id="password_confirmation"
                                                            name="password_confirmation"
                                                            v-model="user.password_confirmation"
                                                            :state="errors.length > 0 ? false : null"
                                                            size="sm"
                                                            type="password"
                                                        />
                                                        <small class="text-danger">{{ errors[0] }}</small>
                                                        <small class="text-danger" v-if="serverErrors && serverErrors.password_confirmation">{{ serverErrors.password_confirmation[0] }}</small>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>
                                            <b-col cols="12" md="3">
                                                <b-row>
                                                    <b-col cols="6" style="padding-top:28px;">
                                                        <b-button
                                                            size="sm"
                                                            variant="primary"
                                                            @click="submit()"
                                                        >
                                                            <b-spinner small class="mr-1" label="Small Spinner" v-if="saving" />
                                                            <span v-if="!saving">Save</span>
                                                            <span v-if="saving">Saving...</span>
                                                        </b-button>
                                                    </b-col>
                                                    <b-col cols="6" style="padding-top:28px;">
                                                        <b-button
                                                            size="sm"
                                                            variant="outline-secondary"
                                                            @click="resetForm()"
                                                        >
                                                            Clear
                                                        </b-button>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                    
                                </b-row>

                                <b-row
                                    v-if="'' + client.type === '0' && role && role.fieldOps"
                                >
                                    <b-col cols="12" md="12">
                                        <b-row>
                                            <b-col cols="2">
                                                <b-form-group labe-for="pers" label="Personal Code">
                                                    <validation-provider #default="{ errors }" name="Personal Code" rules="">
                                                        <b-form-input
                                                            id="pers"
                                                            name="pers"
                                                            v-model="user.pers"
                                                            :state="errors.length > 0 ? false : null"
                                                            size="sm"
                                                        />
                                                        <small class="text-danger">{{ errors[0] }}</small>
                                                        <small class="text-danger" v-if="serverErrors && serverErrors.pers">{{ serverErrors.pers[0] }}</small>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>
                                            <b-col cols="2">
                                                <b-form-group labe-for="email" label="Email/Username">
                                                    <validation-provider #default="{ errors }" name="Email/Username" rules="">
                                                        <b-form-input
                                                            id="email"
                                                            name="email"
                                                            v-model="user.email"
                                                            :state="errors.length > 0 ? false : null"
                                                            size="sm"
                                                        />
                                                        <small class="text-danger">{{ errors[0] }}</small>
                                                        <small class="text-danger" v-if="serverErrors && serverErrors.email">{{ serverErrors.email[0] }}</small>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>
                                            <b-col cols="2">
                                                <b-form-group labe-for="password" label="Password">
                                                    <validation-provider #default="{ errors }" name="Password" rules="">
                                                        <b-form-input
                                                            id="password"
                                                            name="password"
                                                            v-model="user.password"
                                                            :state="errors.length > 0 ? false : null"
                                                            size="sm"
                                                            type="password"
                                                        />
                                                        <small class="text-danger">{{ errors[0] }}</small>
                                                        <small class="text-danger" v-if="serverErrors && serverErrors.password">{{ serverErrors.password[0] }}</small>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>
                                            <b-col cols="2">
                                                <b-form-group labe-for="password_confirmation" label="Confirm Password">
                                                    <validation-provider #default="{ errors }" name="Confirm Password" rules="">
                                                        <b-form-input
                                                            id="password_confirmation"
                                                            name="password_confirmation"
                                                            v-model="user.password_confirmation"
                                                            :state="errors.length > 0 ? false : null"
                                                            size="sm"
                                                            type="password"
                                                        />
                                                        <small class="text-danger">{{ errors[0] }}</small>
                                                        <small class="text-danger" v-if="serverErrors && serverErrors.password_confirmation">{{ serverErrors.password_confirmation[0] }}</small>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>
                                            <b-col cols="12" md="2">
                                                <b-row>
                                                    <b-col cols="6" style="padding-top:28px;">
                                                        <b-button
                                                            size="sm"
                                                            variant="primary"
                                                            @click="submit()"
                                                        >
                                                            <b-spinner small class="mr-1" label="Small Spinner" v-if="saving" />
                                                            <span v-if="!saving">Save</span>
                                                            <span v-if="saving">Saving...</span>
                                                        </b-button>
                                                    </b-col>
                                                    <b-col cols="6" style="padding-top:28px;">
                                                        <b-button
                                                            size="sm"
                                                            variant="outline-secondary"
                                                            @click="resetForm()"
                                                        >
                                                            Clear
                                                        </b-button>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </b-row>
                            </b-form>
<!-- 
                            <pre>
                                {{ user }}
                            </pre> -->
                        </validation-observer>
                    </b-col>
                </b-row>
            </b-card-body>
            <!-- <b-card-body>{{ user }}</b-card-body> -->
        </b-card>
        <!-- ./Form -->

        <!-- Table Container Card -->
        <b-card no-body class="mb-0">
            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <!-- Per Page -->
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>Show</label>
                        <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions" :clearable="false"
                            class="per-page-selector d-inline-block mx-50 select-size-sm" />
                        <label>entries</label>
                    </b-col>
                    <!-- ./Per Page -->

                    <!-- Search -->
                    <b-col cols="12" md="6">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-form-input
                                v-model="searchQuery"
                                class="d-inline-block mr-1"
                                placeholder="Search..."
                                size="sm"
                            />
                        </div>
                    </b-col>
                    <!-- ./Search -->
                </b-row>
                <!-- ./Table Top -->
            </div>

            <!-- Table -->
            <b-table
                striped
                hover
                small
                ref="records"
                class="position-relative"
                :items="fetch"
                responsive
                :fields="columns"
                primary-key="id"
                :sort-by.sync="sortBy"
                show-empty
                empty-text="No records found"
                :sort-desc.sync="isSortDirDesc"
            >

                <template #cell(user)="data">
                    <b-media vertical-align="center">
                        <template #aside>
                        <b-avatar
                            size="32"
                            :src="data.item.avatar"
                            :text="avatarText(data.item.fullName)"
                            :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
                        />
                        </template>
                        <b-link
                            :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
                            class="font-weight-bold d-block text-nowrap"
                        >
                        {{ data.item.person.firstName.trim().substring(0, 1).toUpperCase() + data.item.person.firstName.trim().substring(1).toLowerCase() }} {{ data.item.person.middleName? data.item.person.middleName.trim().substring(0, 1).toUpperCase() + data.item.person.middleName.trim().substring(1).toLowerCase() : '' }} {{ data.item.person.lastName.trim().substring(0, 1).toUpperCase() + data.item.person.lastName.trim().substring(1).toLowerCase() }}
                        </b-link>
                        <small class="text-muted">Phone: {{ data.item.mobile }}</small>
                    </b-media>
                </template>

                <template #cell(roles)="data">
                    <span v-if="data.item.roles && data.item.roles.length > 0">{{ data.item.roles[0].title }}</span>
                </template>

                <template #cell(username)="data">
                    <span v-if="data.item.username">{{ data.item.username }}</span>
                    <span v-else>{{ data.item.email }}</span>
                </template>

                <!-- Column: Status -->
                <template #cell(status)="data">
                    <b-badge
                        pill
                        :variant="`light-${resolveStatusVariant(data.item.status)}`"
                        class="text-capitalize"
                    >
                        <span v-if="'' + data.item.status === '0'">Locked</span>
                        <span v-if="'' + data.item.status === '1'">Active</span>
                        <span v-if="'' + data.item.status === '-1'">Blocked</span>
                    </b-badge>
                </template>

                <template #cell(updated_at)="data">
                    {{ data.item.updated_at | moment('DD/MM/YYYY') }}
                </template>

                <!-- Column: Actions -->
                <template #cell(actions)="data">
                    <b-dropdown
                        variant="link"
                        no-caret
                        :right="$store.state.appConfig.isRTL"
                    >

                        <template #button-content>
                        <feather-icon
                            icon="MoreVerticalIcon"
                            size="16"
                            class="align-middle text-body"
                        />
                        </template>
        
                        <b-dropdown-item @click="invokeUpdateForm(data.item)">
                            <feather-icon icon="EditIcon" />
                            <span class="align-middle ml-50">Edit</span>
                        </b-dropdown-item>
        
                        <b-dropdown-item @click="remove(data.item.id)">
                            <feather-icon icon="TrashIcon" />
                            <span class="align-middle ml-50">Delete</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </template>
            </b-table>

            <!-- Table Footer -->
            <div class="mx-2 mb-2">
                <b-row>
                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start"
                    >
                        <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }} entries</span>
                    </b-col>
                    <!-- Pagination -->
                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-end"
                    >
                        <b-pagination
                            v-model="currentPage"
                            :total-rows="totalRecords"
                            :per-page="perPage"
                            first-number
                            last-number
                            class="mb-0 mt-1 mt-sm-0"
                            prev-class="prev-item"
                            next-class="next-item"
                        >
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div>

        </b-card>
        <!-- ./Table Container Card -->
    </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
    BCard, BCardHeader, BCardBody,
    BRow, BCol,
    BForm, BFormGroup, BFormInput, BFormCheckbox,
    BDropdown, BDropdownItem, BButton, BFormSelect, BFormSelectOption,
    BTable, BPagination, BBadge, BMedia, BAvatar, BLink, BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { avatarText } from '@core/utils/filter'
import { required } from '@validations'
import store from '@/store'
import { ref, onUnmounted, onMounted, computed } from '@vue/composition-api'
import usersStoreModule from '@/views/cromis/users/usersStoreModule'
import useUsersList from '@/views/cromis/users/useUsersList'
import moment from 'moment'
export default {
    props: {},
    components: {
        BCard, BCardHeader, BCardBody,
        BRow, BCol,
        BForm, BFormGroup, BFormInput, BFormCheckbox,
        BDropdown, BDropdownItem, vSelect, BButton, BFormSelect, BFormSelectOption,
        BTable, BPagination, BBadge, BMedia, BAvatar, BLink, BSpinner,
        ValidationProvider, ValidationObserver,
    },
    directives: {},
    setup(props, context) {
        const saving = ref(false)
        const dataForm = ref(null)
        const serverErrors = ref(null)
        const abilities = ref([])
        const roles = ref([])
        const role = ref(null)

        const selections = ref([])
        const levels = ref(null)
        const children = ref([])
        const sectors = ref([])

        const user = ref({
            id: null,
            firstName: null,
            middleName: null,
            lastName: null,
            gender: null,
            isStaff: true,
            registrationNumber: null,
            staffID: null,
            role_id: null,
            permissions: [],
            mobile: null,
            email: null,
            password: null,
            password_confirmation: null,
            status: 1,
            onField: false,
            sector_id: null,
            location_id: null,
            pers: null,
        })

        const client = ref(JSON.parse(localStorage.getItem('userData')).client)

        const CROMIS_STORE_MODULE_NAME = 'cromis-user'

        // Register module
        if (!store.hasModule(CROMIS_STORE_MODULE_NAME)) store.registerModule(CROMIS_STORE_MODULE_NAME, usersStoreModule)

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(CROMIS_STORE_MODULE_NAME)) store.unregisterModule(CROMIS_STORE_MODULE_NAME)
        })

        onMounted(async () => {
            await store.dispatch('cromis-user/abilities')
                        .then(response => {
                            abilities.value = response.data.abilities
                        })
                        .catch(error => {
                            console.log('got error', error)
                        })
            
            await store.dispatch('cromis-user/levels')
                    .then(response => {
                        levels.value = response.data.levels[0]
                        selections.value.push({ level: levels.value.name.toLowerCase(), model: null, label: levels.value.name, child: levels.value.children[0].name.toLowerCase(), locations: [],  })
                        let list = levels.value.children

                        while(list.length > 0){
                            children.value.push(list[0])
                            if(list[0].children > 0){
                                selections.value.push({ level: list[0].name.toLowerCase(), model: null, label: list[0].name, child: list[0].children[0].name.toLowerCase(), locations: [] })
                            }
                            else{
                                selections.value.push({ level: list[0].name.toLowerCase(), model: null, label: list[0].name, child: null, locations: [] })
                            }

                            list = list[0].children
                        }
                    })
                    .catch(error => {
                        console.log('got error', error)
                    })

            await store.dispatch('cromis-user/locations')
                    .then(response => {
                        populateSelections(response.data.locations)
                    })
                    .catch(error => {
                        console.log('got error', error)
                    })

            await store.dispatch('cromis-user/roles')
                        .then(response => {
                            roles.value = response.data.roles
                        })
                        .catch(error => {
                            console.log('got error', error)
                        })
        })

        const populateSelections = (locations) => {
            for(let i = 0; i < locations.length; i++){
                for(let x = 0; x < selections.value.length; x++){
                    if(locations[i].level.name.toLowerCase() === selections.value[x].level){
                        selections.value[x].locations.push(locations[i])
                    }
                }
            }
        }

        const changeFilters = async (location, index) => {

            if(!location){
                for(let i = index + 1; i < selections.value.length; i++){
                    selections.value[i].locations.splice(0)
                    selections.value[i].model = null
                }

                user.value.location_id = null

                // if(index > 0){
                //     locationFilter.value = selections.value[index - 1].model.id
                // }
                // else{
                //     locationFilter.value = null
                // }
            }
            else{
                user.value.location_id = location.id
                
                if(selections.value[index + 1]){
                    selections.value[index + 1].locations.splice(0)
                }
                
                location.children.map((child) => {
                    selections.value[index + 1].locations.push(child)
                })

                if(location.children.length > 0){
                    selections.value[index + 1].model = null
                }
                else{
                    await store.dispatch('cromis-user/sectors', { location_id: location.id })
                                .then(response => {
                                    sectors.value = response.data.sectors
                                })
                                .catch(error => {
                                    console.log('got error', error)
                                })
                }

                // locationFilter.value = selections.value[index].model.id
            }
        }

        const changeRole = () => {
            if(user.value.role_id !== null){
                roles.value.map((myRole) => {
                    if(myRole.id === user.value.role_id){
                        role.value = myRole
                        if(myRole.fieldOps){
                            user.value.onField = true
                        }
                    }
                })
            }
            else{
                role.value = null
            }
        }

        const {
            fetch,
            refetch,
            columns,
            perPage,
            currentPage,
            totalRecords,
            from,
            to,
            meta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            records,

            resolveStatusVariant,
        } = useUsersList()

        // Form Methods
        const invokeUpdateForm = (item) => {
            console.log(item)
            
            user.value = {
                id: item.id,
                firstName: item.person.firstName,
                middleName: item.person.middleName,
                lastName: item.person.lastName,
                gender: item.person.gender,
                isStaff: true,
                registrationNumber: null,
                staffID: item.staffID,
                role_id: item.roles.length > 0 ? item.roles[0].id: null,
                permissions: item.permissions,
                mobile: item.mobile,
                pers: item.pers,
                email: item.email ?? item.username,
                password: null,
                password_confirmation: null,
                status: item.status,
                onField: item.roles.length > 0 ? item.roles[0].fieldOps: false,
                sector_id: item.sectors.length > 0 ?
                                item.sectors[item.sectors.length - 1].id : null,
            }

            changeRole()

            if(item.sectors.length > 0){
                let locations = []
                locations.push(item.sectors[item.sectors.length - 1].address)

                while(locations[locations.length - 1].parent){
                    locations.push(locations[locations.length - 1].parent)
                }

                for(let i = 0; i < locations.length; i++){
                    selections.value[i].locations.map((location) => {
                        if(location.id === locations[locations.length - (i + 1)].id){
                            // changeFilters(locations[locations.length - (i + 1)], i)
                            selections.value[i].model = location
                            changeFilters(location, i)
                        }
                    })
                }
            }
        }

        const resetForm = () => {
            user.value = {
                id: null,
                firstName: null,
                middleName: null,
                lastName: null,
                gender: null,
                isStaff: true,
                registrationNumber: null,
                staffID: null,
                role_id: null,
                permissions: [],
                mobile: null,
                email: null,
                password: null,
                password_confirmation: null,
                status: 1,
                onField: false,
                sector_id: null,
                pers: null,
                location_id: null,
            }

            selections.value.map((selection, index) => {
                selection.model = null

                if(index > 0){
                    selection.locations.splice(0);
                }
            })

            sectors.value.splice(0)
        }

        const isFormValid = async () => {
            let isValid = false 
            await dataForm.value.validate().then(success => {
                isValid = success
            })

            return isValid
        }

        const submit = () => {
            serverErrors.value = null
            // Handle form submit
            if (user.value.id === null || user.value.id === 0)
                handleCreate()
            else
                handleUpdate(user.value)
        }

        const handleCreate = async () => {
            saving.value = true
            const valid = await isFormValid()

            if(!valid){
                saving.value = false
                return
            }

            
            await store.dispatch('cromis-user/create', user.value)
                .then(response => {
                    refetch()
                    saving.value = false
                    resetForm()
                    
                    context.root.$swal({
                        icon: 'success',
                        text: `User account has been created successfully!`,
                        showConfirmButton: true,
                        timer: 5000,
                        customClass: {
                            confirmButton: 'btn btn-primary',
                        },
                        buttonsStyling: false,
                    })
                })
                .catch(error => {
                    saving.value = false
                    console.log(error)
                    if(error.response.status === 422){
                        serverErrors.value = error.response.data.errors
                    }
                    else{
                        context.root.$swal({
                            icon: 'error',
                            title: 'Server Error',
                            text: 'Something went wrong. See tech support',
                            showConfirmButton: true,
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                            buttonsStyling: false,
                        })
                    }
                })
        }

        const handleUpdate = async (item) => {
            saving.value = true
            const valid = await isFormValid()

            if(!valid){
                saving.value = false
                return
            }

            await store.dispatch('cromis-user/update', { id: user.value.id, data: user.value })
                .then(response => {
                    refetch()
                    saving.value = false

                    context.root.$swal({
                        icon: 'success',
                        text: `Changes to ${response.data.title} has been made successfully!`,
                        showConfirmButton: true,
                        timer: 3000,
                        customClass: {
                            confirmButton: 'btn btn-success',
                        },
                        buttonsStyling: false,
                    })
                    resetForm()
                })
                .catch(error => {
                    saving.value = false
                    resetForm()
                    console.log(error)
                    if(error.response.status === 422){
                        serverErrors.value = error.response.data.errors
                    }
                    else{
                        context.root.$swal({
                            icon: 'error',
                            title: 'Server Error',
                            text: 'Something went wrong. See tech support',
                            showConfirmButton: true,
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                            buttonsStyling: false,
                        })
                    }
                })
        }

        const remove = async (id) => {
            await store.dispatch('cromis-user/remove', id)
                        .then(response => {
                            refetch()
                        })
                        .catch(error => {
                            context.root.$swal({
                                icon: 'error',
                                title: 'Server Error',
                                text: 'Something went wrong. See tech support',
                                showConfirmButton: false,
                                customClass: {
                                    confirmButton: 'btn btn-danger',
                                },
                                buttonsStyling: false,
                            })
                        })
        }
        
        return {
            // Collections
            abilities,
            roles,
            role,

            // Form
            client,
            dataForm,
            invokeUpdateForm,
            submit,
            saving,
            serverErrors,
            user,
            selections,
            levels,
            children,
            sectors,
            changeRole,
            changeFilters,
            remove,
            resetForm,

            // Validations
            required,
            avatarText,

            // Table
            fetch,
            columns,
            perPage,
            currentPage,
            totalRecords,
            from,
            to,
            meta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            records,
            refetch,
            resolveStatusVariant,
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
</style>